
import { Capacitor } from '@capacitor/core';
import { mapGetters, mapMutations } from 'vuex';

import { formatPrice } from '@/modules/Helpers';

export default {
    computed: {
        ...mapGetters('order', ['itemsCount', 'originalPrice', 'totalPrice']),
        ...mapGetters(['vat']),
        countString() {
            return `Checkout`;
        },
        totalString() {
            return formatPrice(this.originalPrice);
        },
        isNative() {
            return Capacitor.isNative;
        },
    },
    methods: {
        ...mapMutations('sheet', ['setSheet']),
        openCart() {
            this.setSheet({ type: 'Cart' });
        },
    },
};
