
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('sheet', ['type', 'data']),
        sheet() {
            return () => import(`@/components/${this.type}Sheet`);
        },
    },
};
