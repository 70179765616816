
import { mapMutations, mapGetters } from 'vuex';

export default {
    components: {
        ...mapGetters(['desktopCheckoutStarted']),
    },
    methods: {
        ...mapMutations('modal', ['closeModal']),
        ...mapMutations(['setDesktopCheckoutStarted']),
        close() {
            if (this.desktopCheckoutStarted) {
                this.setDesktopCheckoutStarted(false);
            }

            this.closeModal();
        },
    },
    mounted() {
        this.$refs.overlay.style.opacity = 0.80;
    },
};
