
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    props: {
        large: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters('order', ['deliveryMethod', 'isDelivery', 'address', 'restaurantLocation']),
        ...mapGetters(['loggedInUser']),
        hasAddresses() {
            return this.loggedInUser && this.loggedInUser.addresses.length;
        },
        showDeliveryOption() {
            return (
                !this.restaurantLocation ||
                (this.restaurantLocation && this.restaurantLocation.delivery_option) ||
                this.large || this.$route.name === 'index'
            );
        },
        showPickupOption() {
            return (
                !this.restaurantLocation ||
                (this.restaurantLocation && this.restaurantLocation.pickup_option) ||
                this.large
            );
        }
    },
    watch: {
        deliveryMethod(method) {
            const isDelivery = method === 'delivery';
            if (this.restaurantLocation) {
                const deliveryOption = isDelivery
                    ? this.restaurantLocation.delivery_option
                    : this.restaurantLocation.pickup_option;
                if (deliveryOption && deliveryOption.id) {
                    this.setDeliveryOptionId(deliveryOption.id);
                } else {
                    this.setDeliveryOptionId(null);
                }
            }
        },
    },
    methods: {
        ...mapMutations('order', ['setDeliveryMethod', 'setDeliveryOptionId']),
        ...mapMutations('sheet', ['setSheet']),
        ...mapMutations('location', ['setLocation', 'clearLocation']),
        ...mapMutations('modal', ['setModal']),
        ...mapActions('order', ['changeDeliveryMethod']),
    },
};
