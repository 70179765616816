
import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters('toast', ['msg', 'showClose', 'info']),
        ...mapGetters('modal', { modalType: 'type' }),
    },
    methods: {
        ...mapMutations('toast', ['closeToast']),
    },
};
