
export default {
    props: {
        tag: { type: String, required: false, default: 'div' },
        to: { type: String, required: false, default: '' },
        fullWidth: { type: Boolean, default: false },
        outline: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        small: { type: Boolean, default: false },
        neon: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
    },
};
