
export default {
    name: 'SvgInline',
    props: {
        svg: { type: String, required: true },
        path: {
            type: String,
            default: 'assets/svg/',
        },
    },
    computed: {
        styles() {
            const regex = /viewBox="0 0 ([^ ]+ [^ ]+)"/g;
            const regexResult = regex.exec(this.svgFile)[1] || '1 1';
            const [width, height] = regexResult.split(' ');
            const ratio = (height / width) * 100;
            return {
                paddingTop: `${ratio}%`,
            };
        },
        svgFile() {
            return require(`!svg-inline-loader?classPrefix!@/${this.path}${this.svg}.svg`);
        },
    },
};
