import { render, staticRenderFns } from "./WhitelabelFooter.vue?vue&type=template&id=27d0d806&scoped=true"
import script from "./WhitelabelFooter.vue?vue&type=script&lang=js"
export * from "./WhitelabelFooter.vue?vue&type=script&lang=js"
import style0 from "./WhitelabelFooter.vue?vue&type=style&index=0&id=27d0d806&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d0d806",
  null
  
)

export default component.exports