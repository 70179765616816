
import { mapGetters, mapMutations } from 'vuex';
import { Capacitor } from '@capacitor/core';
import { formatPrice } from '@/modules/Helpers';

export default {
    computed: {
        ...mapGetters(['loggedInUser']),
        ...mapGetters('order', ['isDelivery', 'address', 'itemsCount', 'totalPrice']),
        ...mapGetters('sideDrawer', ['sideDrawerType']),
        ...mapGetters(['vat']),
        cartString() {
            if (this.vat === 'excl') {
                return ` (${this.itemsCount} stk) ${formatPrice(this.totalPrice * 0.8)}`;
            } else {
                return ` (${this.itemsCount} stk) ${formatPrice(this.totalPrice)}`;
            }
        },
        activeOrdersLength() {
            if (this.loggedInUser) {
                return this.loggedInUser.orders.filter(x => x.state === 'confirmed' || x.state === 'placed' || x.state === 'delivery-ongoing').length;
            }
            return 0;
        },
        isNative() {
            return Capacitor.isNative;
        },
        routeName() {
            return this.$route.name;
        },
        showDeliveryMethod() {
            return this.routeName !== 'checkout' && this.routeName !== 'order-uuid';
        },
        isCheckout() {
            return this.routeName === 'checkout';
        },
        isOrder() {
            return this.routeName === 'order-uuid';
        },
        isRestaurantChain() {
            return this.routeName === 'supplier-chain-slug';
        },
        isTransparent() {
            return this.$route.name === 'order-uuid';
        },
        hasZIndex() {
            return (
                this.$route.name !== 'supplier-slug' &&
                this.$route.name !== 'checkout' &&
                this.$route.name !== 'order-uuid'
            );
        },
        btnText() {
            return this.loggedInUser && this.loggedInUser.name ? this.loggedInUser.name : 'Profil';
        },
        showWebsiteMenuItems() {
            if (this.$route.name === 'index') {
                return true;
            }
            return false;
        }
    },
    mounted () {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.showModal && (params.showModal === 'login' || params.showModal === 'register')) {
            this.openLoginModal();
        }
        if (params.updateOrder) {
            this.openCart();
        }
    },
    methods: {
        ...mapMutations('sideDrawer', ['setSideDrawer', 'closeSideDrawer']),
        ...mapMutations('modal', ['setModal']),
        openCart() {
            this.setSideDrawer({ type: 'Cart' });
        },
        closeCart() {
            this.closeSideDrawer();
        },
        openUserModal() {
            this.setModal({ type: 'User' });
        },
        openLoginModal() {
            this.setModal({ type: 'NewLogin' });
        },
    },
};
