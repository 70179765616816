
import { Capacitor } from '@capacitor/core';
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { formatPrice } from '@/modules/Helpers';

export default {
    computed: {
        ...mapGetters('order', ['isDelivery', 'address', 'itemsCount', 'originalPrice', 'totalPrice', 'homeRoute']),
        ...mapGetters(['loggedInUser']),
        ...mapGetters(['vat']),
        isNative() {
            return Capacitor.isNative;
        },
        profileBtnText() {
            return this.loggedInUser && this.loggedInUser.name ? this.loggedInUser.name.split(' ')[0] : 'Profil';
        },
        totalString() {
            if (this.vat === 'excl') {
                return formatPrice(this.originalPrice * 0.8);
            } else {
                return formatPrice(this.originalPrice);
            }
        },
        unseenCoupons() {
            return (
                this.loggedInUser &&
                this.loggedInUser.available_coupons &&
                this.loggedInUser.available_coupons.filter(c => !c.seen).length !== 0
            );
        },
        routeName() {
            return this.$route.name;
        },
        marketplace() {
            let marketplace = null;
            if (typeof window !== 'undefined') {
                marketplace = localStorage.getItem('marketplace');
            }
            return marketplace;
        },
    },
    data: () => ({
        selectedMenuItem: '',
        showMenu: false,
    }),
    mounted() {
        if (window.innerWidth < 769) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.showModal && (params.showModal === 'login' || params.showModal === 'register')) {
                this.selectedMenuItem = 'profile';
                this.$router.push('/user');
            } else this.selectedMenuItem = 'store';
        }
    },
    methods: {
        ...mapActions('order', ['changeDeliveryMethod']),
        ...mapMutations('sheet', ['setSheet']),
        ...mapMutations('modal', ['setModal']),
        openCart() {
            this.setSheet({ type: 'Cart' });
        },
        goToUserPage() {
            if (this.loggedInUser) {
                this.setModal({ type: 'User' });
            } else {
                this.setModal({ type: 'CreateOrLogin' });
            }
            /* const path = window.location.pathname
            const currentUrl = window.location.href;
            let url = new URL(currentUrl + 'user');
            if (path.includes('supplier-chain')) {
                if (!this.loggedInUser) {
                    url.searchParams.append('redirectTo', path);
                }
                url.searchParams.append('backRedirectTo', path);
                this.$router.push(url.href.replace(currentUrl, '/').replace('&', '?'));
            } else {
                this.$router.push('/user');
            } */
        },
        goToStorePage() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let backRedirectTo = urlParams.get('backRedirectTo') ? urlParams.get('backRedirectTo') : null;
            if (backRedirectTo) {
                this.$router.push(backRedirectTo);
            } else {
                this.$router.push(this.homeRoute);
            }
        },
        doChangeDeliveryMethod(method) {
            this.$store.commit('restaurants/setCurrentCategory', null);
            this.changeDeliveryMethod({ method: method, address: this.address });
        },
        goHome() {
            this.$router.push('/');
            this.showMenu = false;
        },
        goBreakfast() {
            this.$router.push('/happy-breakfast');
            this.showMenu = false;
        },
        goMoments() {
            this.$router.push('/happy-moments');
            this.showMenu = false;
        },
        goFruit() {
            this.$router.push('/happy-fruit');
            this.showMenu = false;
        },
        goMeetings() {
            this.$router.push('/happy-meetings');
            this.showMenu = false;
        },
        goTakeaway() {
            this.$router.push('/happy-takeaway');
            this.showMenu = false;
        },
        goHappy() {
            this.$router.push('/happy-lunch');
            this.showMenu = false;
        },
        goOrders() {
            this.$router.push('/orders');
            this.showMenu = false;
        },
    },
};
