
export default {
    name: 'Icon',
    props: {
        icon: { type: String, required: true },
        small: Boolean,
        light: Boolean,
        default: Boolean,
        noFill: Boolean,
        noStroke: Boolean,
        width: { type: Number, required: true },
    },
    data() {
        return {};
    },
    computed: {
        classes() {
            return {
                [`icon--small`]: this.small,
                [`icon--light`]: this.light,
                [`icon--default`]: this.default,
                [`icon--noFill`]: this.noFill,
                [`icon--noStroke`]: this.noStroke,
            };
        },
        styles() {
            const width = this.width && `${this.width}rem`;
            return {
                width,
            };
        },
    },
};
