
import { Capacitor, Plugins } from '@capacitor/core';
import { mapGetters, mapMutations, mapActions } from 'vuex';

const { SplashScreen } = Plugins;

export default {
    data: () => ({
        timerPassed: false,
        locationPassed: false,
        duration: 3200,
        imageNo: 1,
        showLogo: false
    }),
    computed: {
        ...mapGetters('location', ['location', 'locationAllowed']),
        ...mapGetters('order', ['isDelivery', 'address']),
        ...mapGetters(['loggedInUser']),
        isAndroid() {
            return Capacitor.platform === 'android';
        },
        logoWidth() {
            return this.isAndroid ? 13 : 15.1875;
        },
    },
    watch: {
        locationAllowed() {
            this.locationPassed = true;
            this.$nextTick(this.checkSplashStatus);
        },
        loggedInUser() {
            this.setSplashSeen(true);
        }
    },
    async mounted() {
        setTimeout(async () => {
            await SplashScreen.hide();
        }, 1000);
        this.initLocationInterval();
        if (this.loggedInUser) {
            this.setSplashSeen(true);
        }
    },
    methods: {
        ...mapMutations(['setSplashSeen']),
        ...mapMutations('sheet', ['setSheet']),
        ...mapActions('location', ['getLocation', 'initLocationInterval']),
        goToUserPage() {
            this.$router.push('/user');
            this.setSplashSeen(true)
        }
    },
};
