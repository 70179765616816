
import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            showUserMenu: false,
        };
    },
    computed: {
        ...mapGetters('supplier', ['getSupplier']),
        ...mapGetters(['loggedInUser']),
        supplier() {
            return this.getSupplier;
        },
    },
    methods: {
        ...mapMutations('modal', ['setModal']),
        toggleUserMenu() {
            this.showUserMenu = !this.showUserMenu;
        },
        getInitials(name) {
            return name
                .split(' ')
                .map(part => part[0])
                .join('')
                .toUpperCase();
        },
        openLoginModal() {
            this.setModal({ type: 'NewLogin' });
        },
        openCreateUserModal() {
            this.setModal({ type: 'CreateUser' });
        },
        openUserModal() {
            this.setModal({ type: 'User' });
            this.showUserMenu = false;
        },
        async logout() {
            await this.$auth.logout();
            this.showUserMenu = false;
        },
    },
    mounted() {
        // Close user menu when clicking outside
        document.addEventListener('click', e => {
            if (!this.$el.contains(e.target)) {
                this.showUserMenu = false;
            }
        });
    },
};
