
export default {
    props: {
        tag: { type: String, required: false, default: 'div' },
        to: { type: String, required: false, default: '' },
        badgeText: { type: String, default: null },
        small: { type: Boolean, required: false, default: false },
        wide: { type: Boolean, required: false, default: false },
    },
};
