
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('modal', ['type', 'data']),
        modal() {
            return () => import(`@/components/${this.type}Modal`);
        },
    },
};
